export default {
  name: "notFound",
  setup() {
    const doHome = function () {
      const _path = window.location.origin + window.location.pathname;
      window.location.href = _path + '#/';
    };
    return {
      doHome
    };
  }
};